<template>
  <div class="regions col-12">
    <div class="regions__search">
      <input
        type="text"
        class="input"
        placeholder="Найти область"
        @change="filteredRegions()"
        v-model="search"
      />
      <i class="fas fa-search"></i>
    </div>
    <div class="regions__add" @click="addNewRegion()">+ Добавить область</div>
    <div
      class="regions__item"
      v-for="region in filteredRegions"
      :key="region.id"
      @click="regionDetail(region)"
    >
      <div class="row">
        <div class="col-10 regions__item__text">{{region.name}}</div>
        <div class="col-2 d-flex justify-content-end align-items-center">
          <i class="fas fa-pencil-alt icon-edit" v-on:click.stop="doEditRegion(region.id)"></i>
        </div>
      </div>
    </div>
    <div class="setting-default">
      <i class="fas fa-sliders-h" @click="goToSetting()"></i>
      <p @click="goToSetting()">Настройки</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cities: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      regions: this.cities,
      search: ""
    };
  },
  methods: {
    doEditRegion(id) {
      const region = this.regions.find(region => region.id == id);
      this.$store.commit("changeActiveScreen", "formRegions");
      this.$store.commit("regionChange", region);
    },
    addNewRegion() {
      this.$store.commit("regionChange", null);
      this.$store.commit("changeActiveScreen", "formRegions");
    },
    regionDetail(region) {
      this.$store.commit("regionChange", region);
      this.$store.commit("changeActiveScreen", "zoneList");
    },
    goToSetting() {
      this.$store.commit("changeActiveScreen", "setting");
    }
  },
  computed: {
    filteredRegions() {
      let search = this.search.toLowerCase();
      return this.regions.filter(region => {
        return !search ? true : region.name.toLowerCase().indexOf(search) > -1;
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.regions__item {
  cursor: pointer;
}
</style>
