export default {
    state: {
        addControl: {
            isVisiblePanel: false,
            addBtn: false,
            editPanel: false,
            areaEditShow: false
        },
        canAddNewPolygon: false,
        zone: {
            id: null,
            name: "",
            color: "#007bff",
            price: "",
            delivery_days: "",
            polygons: [],
            description: "",
            additions: []
        },
        canDeletePolygon: false,
        editType: "edit",
        history: [],
        historyIndex: 0
    },
    mutations: {
        changeAddControl(state, payload) {
            state.addControl = payload;
        },
        changeCanAddNewPolygon(state, payload) {
            state.canAddNewPolygon = payload;
        },
        changeCurrentZone(state, payload) {
            state.zone = payload;
        },
        changeCanDeletePolygon(state, payload) {
            state.canDeletePolygon = payload;
        },
        changeEditType(state, payload) {
            state.editType = payload;
        },
        changeHistory(state, payload) {
            if (state.historyIndex + 1 < state.history.length) {
                state.history = state.history.splice(0, state.historyIndex + 1);
                state.history = [...state.history, { ...payload }];
            } else {
                state.history = [...state.history, { ...payload }];
            }
            state.historyIndex = state.history.length - 1;
        },
        clearHistory(state, payload) {
            state.history = payload;
        },
        doUndo(state) {
            if (state.history.length > 0 && state.historyIndex > 0) {
                state.historyIndex -= 1;
                state.zone = _.cloneDeep(state.history[state.historyIndex]);
            }

            if (state.historyIndex === 0) {
                state.zone = _.cloneDeep(state.history[state.historyIndex]);
            }
        },
        doRedo(state) {
            if (
                state.history.length &&
                state.historyIndex != state.history.length - 1
            ) {
                state.historyIndex += 1;
                state.zone = _.cloneDeep(state.history[state.historyIndex]);
            }
        },
        changeCanUndo(state, payload) {
            state.canUndo = payload;
        }
    },

    getters: {
        getAddControl: state => state.addControl,
        getcanAddNewPolygon: state => state.canAddNewPolygon,
        getZone: state => state.zone,
        getStateCanDeletePolygon: state => state.canDeletePolygon,
        getZoneEditType: state => state.editType
    }
};
