import Vue from "vue";
import Vuex from "vuex";
import common from "./common";
import regions from "./regions";
import zones from "./zones";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        common,
        regions,
        zones
    }
});
