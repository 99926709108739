<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9 pr-0" id="map" style="height:100%;">
        <add-control v-if="isVisibleAddControl.isVisiblePanel"></add-control>
      </div>
      <div class="col-3 price-zone__control">
        <empty-region v-if="activeScreen.emptyRegions" :lat="lat" :long="long" :scope="scale"></empty-region>

        <form-region v-if="activeScreen.formRegions" :lat="lat" :long="long" :scope="scale"></form-region>

        <empty-zone v-if="activeScreen.emptyZones"></empty-zone>

        <form-zone v-if="activeScreen.formZones"></form-zone>

        <div class="row wrapper wrapper--grey wrapper--start" v-if="activeScreen.regionList">
          <region-list :cities="cities"></region-list>
        </div>

        <div
          class="row wrapper wrapper--white wrapper--start"
          v-if="activeScreen.zoneList && $store.state.regions.region && $store.state.regions.region.zones"
        >
          <zone-list :zones="currentRegion.zones"></zone-list>
        </div>

        <setting v-if="activeScreen.setting"></setting>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "vue-color-picker-wheel";
import uuidv4 from "uuid/v4";
import axios from "axios";
import emptyRegion from "./regions/emtyRegion";
import regionList from "./regions/regionList";
import zoneList from "./zones/zoneList";
import regionEdit from "./regions/formRegion";
import emptyZone from "./zones/emptyZone";
import formRegion from "./regions/formRegion";
import formZone from "./zones/formZone";
import addControl from "./zones/addControl";
import setting from "./settingDefault";

export default {
  props: ["project_type"],
  data() {
    return {
      mapObject: {},
      polygonMaxPoint: 9999,
      lat: 55.72904172564678,
      long: 37.501938256417425,
      color: "#000",
      is_dinamyc_price: process.env.MIX_IS_DYNAMIC_PRICE,
      activeScreen: this.$store.getters.getScreens,
      zone_id: null,
      opacity: 0.3,
      scale: this.$store.getters.getScale,
      cities: [],
      currentRegion: this.$store.getters.getRegion,
      currentZone: this.$store.getters.getZone,
      isVisibleAddControl: false,
      coordinates: [55.72904172564678, 37.501938256417425],
      history: [],
      isEditZone: this.$store.state.common.isEdittingZone,
      canUndo: false,
      canRedo: false
    };
  },

  methods: {
    initMap() {
      const mapOptions = {
        center: [55.72904172564678, 37.501938256417425],
        zoom: this.scale,
        controls: ["zoomControl"]
      };
      this.mapObject = new ymaps.Map("map", mapOptions);

      axios
        .get("regions")
        .then(response => {
          if (response.data.data.length) {
            this.$store.commit("regionsChange", response.data.data);
            this.$store.commit("changeActiveScreen", "regionList");
          } else {
            this.$store.commit("changeActiveScreen", "emptyRegions");
          }
        })
        .catch(e => {});

      this.getRegions();

      let t = this;
      this.mapObject.events.add("boundschange", function(event) {
        let centerCoordinates = t.mapObject.getCenter();
        t.scale = t.mapObject.getZoom();
        t.$store.commit("scaleChange", t.scale);
        t.lat = centerCoordinates[0];
        t.long = centerCoordinates[1];
      });
    },

    doCreatePolygon() {
      const id = uuidv4();

      // Создаем многоугольник без вершин.
      var newPolygon = new ymaps.Polygon(
        [],
        {},
        {
          // Курсор в режиме добавления новых вершин.
          editorDrawingCursor: "crosshair",
          // Максимально допустимое количество вершин.
          editorMaxPoints: this.polygonMaxPoint,
          // Цвет заливки.
          fillColor: this.currentZone.color,
          opacity: this.opacity,
          // Ширина обводки.
          strokeWidth: 3
        }
      );

      // Добавляем многоугольник на карту.
      this.mapObject.geoObjects.add(newPolygon);

      // В режиме добавления новых вершин меняем цвет обводки многоугольника.
      var stateMonitor = new ymaps.Monitor(newPolygon.editor.state);
      let count = 0;
      newPolygon.events.add(
        "geometrychange",
        _.throttle(e => {
          let coordinates = newPolygon.geometry.getCoordinates();
          const polygon = this.currentZone.polygons.find(
            polygon => polygon.id == id
          );

          if (coordinates[0] && coordinates[0].length != 0) {
            if (polygon) {
              polygon.id = id;
              polygon.coordinates = coordinates[0];
              if (polygon.coordinates.length <= 2) {
                this.currentZone.polygons = this.currentZone.polygons.filter(
                  p => p.id !== id
                );
              }
            } else {
              newPolygon.options.set("id", id);
              this.currentZone.polygons.push({
                id,
                coordinates: coordinates[0]
              });

              this.$store.commit("changeCanAddNewPolygon", false);
            }
          }
          if (count > 0) {
            this.$store.commit("changeHistory", _.cloneDeep(this.currentZone));
          }
          count++;
        }, 0)
      );

      stateMonitor.add("drawing", e => {
        let coordinates = newPolygon.geometry.getCoordinates();
        const polygon = this.currentZone.polygons.find(p => p.id == id);

        if (coordinates[0] && coordinates[0].length != 0) {
          if (polygon) {
            polygon.id = id;
            polygon.coordinates = coordinates[0];
          } else {
            newPolygon.options.set("id", id);
            this.currentZone.polygons.push({
              id,
              coordinates: coordinates[0]
            });

            this.$store.commit("changeCanAddNewPolygon", false);
          }
        }
      });

      newPolygon.editor.startDrawing();
    },

    doDeletePolygon() {
      const t = this;

      this.mapObject.geoObjects.events.add(
        "click",
        function testName(e) {
          if (e.get("target").getParent() == this) {
            this.remove(e.get("target"));
            t.currentZone.polygons = t.currentZone.polygons.filter(
              zone => zone.id !== e.get("target").options.get("id")
            );
            t.$store.commit("changeCurrentZone", t.currentZone);
          }
        },
        this.mapObject.geoObjects
      );
    },

    getRegions() {
      axios
        .get("regions")
        .then(response => {
          this.mapObject.geoObjects.removeAll();
          this.cities = response.data.data;

          for (let city of this.cities) {
            for (let zone of city.zones) {
              for (let polygon of zone.polygons) {
                let newPolygon = new ymaps.Polygon(
                  [polygon.coordinates, []],
                  {},
                  {
                    fillColor: zone.color,
                    strokeWidth: 3,
                    opacity: this.opacity
                  }
                );
                let id = uuidv4();
                zone.zone_id = id;
                polygon.id = uuidv4();
                newPolygon.options.set("id", id);
                this.mapObject.geoObjects.add(newPolygon);
              }
            }
          }

          this.$store.commit("updateCities", false);
        })
        .catch(e => {});
    },

    currentRegionDraw() {
      this.mapObject.geoObjects.removeAll();
      if (
        Object.keys(this.currentRegion).length != 0 &&
        this.currentRegion.zones
      ) {
        for (let zone of this.currentRegion.zones) {
          for (let polygon of zone.polygons) {
            let newPolygon = new ymaps.Polygon(
              [polygon.coordinates, []],
              {},
              {
                fillColor: zone.color,
                strokeWidth: 3,
                opacity: this.opacity
              }
            );
            let id = uuidv4();
            zone.zone_id = id;
            polygon.id = uuidv4();
            newPolygon.options.set("id", id);
            this.mapObject.geoObjects.add(newPolygon);
          }
        }

        this.mapObject.setCenter(this.currentRegion.coordinates);
        this.mapObject.setZoom(this.currentRegion.scale);
      }
    },

    selectZone() {
      this.mapObject.geoObjects.removeAll();
      for (let zone of this.currentRegion.zones) {
        if (zone.isActive) {
          for (let polygon of zone.polygons) {
            let newPolygon = new ymaps.Polygon(
              [polygon.coordinates, []],
              {},
              {
                fillColor: zone.color,
                strokeWidth: 3,
                opacity: this.opacity
              }
            );
            let id = uuidv4();
            zone.zone_id = id;
            polygon.id = uuidv4();
            newPolygon.options.set("id", id);
            this.mapObject.geoObjects.add(newPolygon);
          }
        }
      }
    },

    doEditZone() {
      this.mapObject.geoObjects.events.types.click = undefined;
      this.$store.commit("changeIsEdittigZone", true);
      this.$store.commit("changeCanDeletePolygon", false);
      this.mapObject.geoObjects.removeAll();

      let zones = this.currentRegion.zones.filter(zone => {
        return zone.id !== this.currentZone.id;
      });
      for (const zone of zones) {
        for (let polygon of zone.polygons) {
          let newPolygon = new ymaps.Polygon(
            [polygon.coordinates, []],
            {},
            {
              fillColor: zone.color,
              strokeWidth: 3,
              opacity: this.opacity
            }
          );
          let id = uuidv4();
          zone.zone_id = id;
          polygon.id = uuidv4();
          newPolygon.options.set("id", id);
          this.mapObject.geoObjects.add(newPolygon);
        }
      }

      for (const polygon of this.currentZone.polygons) {
        let newPolygon = new ymaps.Polygon(
          [polygon.coordinates, []],
          {},
          {
            fillColor: this.currentZone.color,
            // Курсор в режиме добавления новых вершин.
            editorDrawingCursor: "crosshair",
            // Максимально допустимое количество вершин.
            editorMaxPoints: this.polygonMaxPoint,
            // Making the polygon transparent for map events.
            interactivityModel: "default#transparent",
            strokeWidth: 3,
            opacity: this.opacity
          }
        );

        newPolygon.options.set("id", polygon.id);
        this.mapObject.geoObjects.add(newPolygon);

        newPolygon.events.add(
          "geometrychange",
          _.throttle(e => {
            const polygon = this.currentZone.polygons.find(
              poly => poly.id == e.originalEvent.target.options.get("id")
            );

            polygon.coordinates = [...newPolygon.geometry.getCoordinates()[0]];
            this.$store.commit("changeHistory", _.cloneDeep(this.currentZone));
          }, 0)
        );
        newPolygon.editor.startEditing();
      }
    }
  },

  computed: {
    isEdittingZone() {
      return this.$store.getters.getIsEditZone;
    },
    isUpdateCities() {
      return this.$store.getters.getStateCities;
    },
    isUpdateCurrentRegion() {
      return this.$store.getters.getStateCurrentRegionDraw;
    },
    currentActiveScreen() {
      return this.$store.getters.getScreens;
    },
    addPolygonControl() {
      return this.$store.getters.getAddControl;
    },
    regionsState() {
      return this.$store.getters.getRegions;
    },
    regionState() {
      return this.$store.getters.getRegion;
    },
    canAddNewPolygon() {
      return this.$store.getters.getcanAddNewPolygon;
    },
    canRemovePolygon() {
      return this.$store.getters.getStateCanDeletePolygon;
    },
    zoneState() {
      return this.$store.getters.getZone;
    },
    zoneEditType() {
      return this.$store.getters.getZoneEditType;
    },
    totalScale() {
      return this.$store.getters.getScale;
    }
  },
  watch: {
    scale(n, o) {
      this.mapObject.setZoom(n);
    },
    long(n, o) {
      this.mapObject.setCenter([this.lat, this.long]);
    },
    totalScale(n, o) {
      this.mapObject.setZoom(n);
    },
    isUpdateCities(n, o) {
      n ? this.getRegions() : null;
    },
    isUpdateCurrentRegion(n, o) {
      this.selectZone();
    },
    regionsState(n, o) {
      this.cities = this.$store.getters.getRegions;
    },
    regionState(n, o) {
      this.currentRegion = this.$store.getters.getRegion;
    },
    currentActiveScreen(n, o) {
      this.activeScreen = this.$store.getters.getScreens;
    },
    addPolygonControl(n, o) {
      this.isVisibleAddControl = this.$store.getters.getAddControl;
    },
    canAddNewPolygon(n, o) {
      n === true ? this.doCreatePolygon() : null;
    },
    canRemovePolygon(n, o) {
      n
        ? this.doDeletePolygon()
        : (this.mapObject.geoObjects.events.types.click = undefined);
    },
    zoneState: {
      handler(newVal) {
        this.currentZone = this.$store.getters.getZone;
        if (this.currentZone.polygons.length < 2) {
          this.mapObject.geoObjects.events.types.click = undefined;
          this.$store.commit("changeCanDeletePolygon", false);
        }
      },
      deep: true
    },
    currentRegion: {
      handler(newVal) {
        if (!newVal) {
          this.getRegions();
          this.mapObject.setCenter(this.coordinates);
        } else {
          let isEdittingZone = this.$store.state.common.isEdittingZone;
          let isActiveDeletPolygon = this.$store.getters
            .getStateCanDeletePolygon;

          if (!isEdittingZone) {
            if (isActiveDeletPolygon) {
              this.selectZone();
            } else if (!this.$store.state.common.isEdittingZone) {
              this.currentRegionDraw();
            }
          }
        }
      },
      deep: true
    },
    currentZone(n, o) {
      this.doEditZone();
    },
    zoneEditType(n, o) {
      n === "show" ? this.getRegions() : this.doEditZone();
    },
    isEdittingZone(n, o) {
      this.isEditZone = n;
      n ? this.doEditZone() : this.getRegions();
    }
  },
  mounted() {
    ymaps.ready(this.initMap);
    const region = this.$store.getters.getRegion;
    if (region) {
      this.currentZone.regionId = region.id;
    }
    this.$store.commit("setProjectType", this.project_type);
  },
  components: {
    ColorPicker,
    emptyRegion,
    regionList,
    regionEdit,
    emptyZone,
    formRegion,
    formZone,
    addControl,
    zoneList,
    setting
  }
};
</script>

<style lang='scss' scoped>
.zones {
  &__item {
    cursor: pointer;
  }
  .btn {
    width: 100%;
  }
}
.icon {
  cursor: pointer;
}
</style>
