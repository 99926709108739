<template>
  <div class="zones col-12">
    <div class="pl-0 col-12 come-back">
      <img src="../../../img/arrow-back.svg" @click="goToBack()" />
      <p>{{region.name}}</p>
    </div>
    <div class="zones__add" @click="addNewZone()">+ Добавить зону</div>

    <zone-item v-for="(zone,index) in zoneList" :key="index" :zone="zone"></zone-item>
  </div>
</template>

<script>
import zoneItem from "./item";
export default {
  props: {
    zones: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      zoneList: [...this.zones],
      region: this.$store.state.regions.region
    };
  },
  methods: {
    addNewZone() {
      this.$store.commit("changeCanDeletePolygon", false);
      this.$store.commit("changeIsEdittigZone", false);
      this.$store.commit("changeCurrentZone", {
        id: null,
        name: "",
        color: "#007bff",
        price: "",
        delivery_days: "",
        polygons: [],
        description: "",
        additions: []
      });
      this.$store.commit("changeActiveScreen", "formZones");
      this.$store.commit("changeAddControl", {
        isVisiblePanel: true,
        addBtn: true,
        editPanel: false
      });
    },
    goToBack() {
      this.$store.commit("scaleChange", 10);
      this.$store.commit("regionChange", null);
      this.$store.commit("changeActiveScreen", "regionList");
    }
  },
  components: {
    zoneItem
  }
};
</script>

<style lang='scss' scoped>
.active {
  color: green;
}
</style>
