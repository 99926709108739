<template>
  <div class="row wrapper wrapper--center wrapper--white">
    <div class="col-12 come-back">
      <img src="../../../img/arrow-back.svg" @click="goToBack()" />
      <p>{{region.name}}</p>
    </div>
    <div class="col-12 align-self-start">
      <div class="empty-zone">
        <img src="../../../img/zone-area.svg" class="empty-zone__img" />
        <h2 class="empty-zone__title">Добавьте зону доставки</h2>
        <p
          class="empty-zone__text"
        >Тут нужно сделать описание что такое зона и зачем её нужно добавлять.</p>
        <button
          class="btn btn-primary w-100 empty-zone__btn"
          @click="showFormAddZone()"
        >Добавить зону</button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      region: {}
    };
  },
  methods: {
    goToBack() {
      this.$store.commit("changeActiveScreen", "regionList");
    },
    showFormAddZone() {
      this.$store.commit("changeAddControl", {
        isVisiblePanel: true,
        addBtn: true,
        editPanel: false,
        areaEditShow: false
      });
      this.$store.commit("changeCurrentZone", {
        id: null,
        name: "",
        color: "#368ff6",
        price: null,
        delivery_days: null,
        polygons: [],
        additions: []
      });
      this.$store.commit("changeActiveScreen", "formZones");
    }
  },
  mounted() {
    this.region = this.$store.state.regions.region;
  }
};
</script>
