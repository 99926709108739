<template>
  <div
    class="zones__item"
    :class="{ 'zones__item--active': isActive }"
    @click="changeActiveZone(itemZone)"
  >
    <div class="row">
      <div class="col-1">
        <div class="zones__item__color" :style="{'background-color': itemZone.color}"></div>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-12">
            <p class="zones__item__title">{{itemZone.name}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <div class="col-12 pl-0">
              <p class="zones__item__price">{{ itemZone.price | currency('RUB') }}</p>
            </div>
          </div>
          <div class="col-2">
            <i
              class="fas fa-pencil-alt zones__item__edit icon-edit"
              v-on:click.stop="editZone(itemZone)"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zone: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      itemZone: this.zone,
      isActive: false
    };
  },
  methods: {
    changeActiveZone(zone) {
      this.isActive = !this.isActive;
      this.$store.commit("changeActiveZone", {
        isActive: this.isActive,
        currentZone: this.itemZone
      });
      this.$store.commit("toggleCurrentRegionDraw", true);
    },
    editZone(zone) {
      this.$store.commit("clearHistory", []);
      this.$store.commit("changeHistory", _.cloneDeep(this.zone));
      this.isActive = true;
      this.$store.commit("changeActiveZone", {
        isActive: this.isActive,
        currentZone: this.itemZone
      });
      this.$store.commit("changeCurrentZone", zone);
      this.$store.commit("changeAddControl", {
        isVisiblePanel: true,
        addBtn: false,
        editPanel: true,
        areaEditShow: false
      });
      this.$store.commit("changeActiveScreen", "formZones");
      this.$store.commit("changeIsEdittigZone", true);
    }
  }
};
</script>