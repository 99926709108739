export default {
    state: {
        region: {
            id: null,
            name: "",
            scale: 10,
            coordinates: [],
            zones: []
        },
        regions: [],
        filteredRegions: [],
        currentRegionDraw: false
    },
    mutations: {
        regionChange(state, payload) {
            state.region = payload;
        },
        regionsChange(state, payload) {
            state.regions = payload;
        },
        currentRegionAddNewZone(state, payload) {
            let region = state.regions.filter(region => region.id == payload);
            state.region = region[0];
        },
        changeActiveZone(state, payload) {
            for (const item of state.region.zones) {
                if (item.id === payload.currentZone.id)
                    item.isActive = payload.isActive;
            }
        },
        toggleCurrentRegionDraw(state) {
            state.currentRegionDraw = !state.currentRegionDraw;
        }
    },

    getters: {
        getRegion: state => state.region,
        getRegions: state => state.regions,
        getStateCurrentRegionDraw: state => state.currentRegionDraw
    }
};
