export default {
    state: {
        scale: 10,
        isUpdateCities: false,
        screens: {
            emptyRegions: false,
            emptyZones: false,
            formRegions: false,
            formZones: false,
            regionList: false,
            zoneList: false,
            setting: false
        },
        isEdittingZone: false,
        projectType: ""
    },
    mutations: {
        scaleChange(state, payload) {
            state.scale = payload;
        },
        updateCities(state, payload) {
            state.isUpdateCities = payload;
        },
        changeActiveScreen(state, payload) {
            for (const key in state.screens) {
                if (state.screens.hasOwnProperty(key)) {
                    state.screens[key] = false;
                }
            }
            if (payload) {
                state.screens[payload] = true;
            }
        },
        changeIsEdittigZone(state, payload) {
            state.isEdittingZone = payload;
        },
        setProjectType(state, payload) {
            state.projectType = payload;
        }
    },

    getters: {
        getScale: state => state.scale,
        getStateCities: state => state.isUpdateCities,
        getScreens: state => state.screens,
        getIsEditZone: state => state.isEdittingZone
    }
};
