<template>
  <div class="row wrapper wrapper--white wrapper--start">
    <div class="col-12 setting">
      <h2>Настройки</h2>
      <div class="form form--zone form--zone--create">
        <div class="row">
          <div class="col-12 mb-2">
            <h3>Данные по умолчанию</h3>
          </div>
          <div class="col-12 mb-2" v-if="projectType!=='kapous'">
            <div class="row">
              <div class="col-6">
                <input type="radio" class="radio" min="0" id="radio1" name="price_tipe" />
                <label for="radio1">Фикс</label>
              </div>
              <div class="col-6">
                <input type="radio" class="radio" id="radio2" name="price_tipe" />
                <label for="radio2">Источник</label>
              </div>
            </div>
          </div>

          <div class="col-12 mb-2 input--price">
            <input type="number" class="input" placeholder="Цена" v-model="price" />
          </div>
          <div class="col-12 mb-3 input--delivery-days">
            <input
              type="number"
              class="input"
              placeholder="Срок доставки"
              min="0"
              v-model="delivery_days"
            />
          </div>
          <div class="col-12 addition">
            <div class="row" v-for="(item, index) in additions" :key="index">
              <div class="col-12">
                <hr />
                <div class="row">
                  <h5 class="col-10 addition__title">Сумма заказа</h5>
                  <div class="col-2 d-flex justify-content-end align-items-center">
                    <i class="fas fa-times addition__delete" @click="deleteAddition(item.id)"></i>
                  </div>
                </div>
              </div>

              <div class="col-5 addition__input pr-0">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="от"
                  v-model="item.orderSum.from"
                />
              </div>
              <div class="col-2 p-0 d-flex justify-content-center align-items-center">-</div>
              <div class="col-5 addition__input pl-0">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="до"
                  v-model="item.orderSum.to"
                />
              </div>
              <div class="col-12 mt-3 mb-3 addition__input">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="Цена"
                  v-model="item.deliveryPrice"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn--white w-100" @click="addConditions()">+ Добавить условие</button>
          </div>
          <div class="col-12 mb-3">
            <textarea
              cols="100"
              class="form-control"
              rows="5"
              placeholder="Описание"
              v-model="description"
            ></textarea>
          </div>

          <div class="col-12">
            <button
              class="btn btn-primary w-100"
              @click="saveSettings()"
              :disabled="!formIsValid"
            >Сохранить</button>
          </div>
          <div class="col-12 d-flex justify-content-center error">
            <p>{{error}}</p>
          </div>
        </div>
      </div>
      <p class="cancel" @click="cancel()">Отмена</p>
    </div>
  </div>
</template>

<script>
import uuidv4 from "uuid/v4";
export default {
  data() {
    return {
      price: null,
      delivery_days: null,
      description: "",
      additions: [],
      error: "",
      projectType: this.$store.state.common.projectType
    };
  },
  methods: {
    cancel() {
      let regions = this.$store.state.regions.regions;
      if (regions.length) {
        this.$store.commit("changeActiveScreen", "regionList");
      } else {
        this.$store.commit("changeActiveScreen", "emptyRegions");
      }
    },
    saveSettings() {
      axios
        .put(`delivery-settings`, {
          price: this.price,
          delivery_days: this.delivery_days,
          description: this.description,
          additions: this.additions
        })
        .then(response => {
          this.error = "";
          let data = response.data.data;
          this.price = data.price;
          this.description = data.description;
          this.delivery_days = data.delivery_days;
          this.additions = data.additions;
        })
        .catch(e => {
          this.error = "Произошла ошибка на сервере";
          let regions = this.$store.state.regions.regions;
          if (regions) {
            this.$store.commit("changeActiveScreen", "regionList");
          } else {
            this.$store.commit("changeActiveScreen", "emptyRegions");
          }
        });
    },
    addConditions() {
      this.additions.push({
        id: uuidv4(),
        orderSum: {
          from: null,
          to: null
        },
        deliveryPrice: null
      });
    },
    deleteAddition(id) {
      this.additions = this.additions.filter(a => a.id !== id);
    }
  },
  mounted() {
    axios
      .get("delivery-settings")
      .then(response => {
        this.error = "";
        let data = response.data.data;
        this.price = data.price;
        this.description = data.description;
        this.delivery_days = data.delivery_days;
        this.additions = data.additions || [];
      })

      .catch(e => {
        this.error = "Произошла ошибка на сервере";
      });
  },
  computed: {
    formIsValid() {
      if (!this.price || !this.delivery_days || !this.description) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
