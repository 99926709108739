<template>
  <div
    class="row wrapper wrapper--grey"
    :class="[isVisibleFormRegion ? 'new-region-wrapper' : 'empty-wrapper']"
  >
    <div class="col-10 empty-region" v-if="!isVisibleFormRegion">
      <img src="../../../img/region-area.svg" class="empty-region__img" />
      <h2 class="empty-region__title">Добавить первую область доставки</h2>
      <p
        class="empty-region__text"
      >Чтобы начать работу, добавьте первую область доставки. Тут нужно описать, что такое область и зачем её нужно добавлять.</p>
      <button
        class="btn btn-primary empty-region__btn"
        @click="doChangeVisibleFormAddRegion()"
      >Добавить область</button>
    </div>

    <div class="setting-default">
      <i class="fas fa-sliders-h" @click="goToSetting()"></i>
      <p @click="goToSetting()">Настройки</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      isVisibleFormRegion: false
    };
  },
  methods: {
    doChangeVisibleFormAddRegion() {
      this.$store.commit("regionChange", {
        id: null,
        name: null,
        latitude: null,
        longitude: null,
        scale: this.$store.getters.getScale
      });

      this.$store.commit("changeActiveScreen", "formRegions");
    },
    goToSetting() {
      this.$store.commit("changeActiveScreen", "setting");
    }
  },
  computed: {
    isVisibleAddRegion() {
      return this.$store.getters.getVisibleFormRegion;
    }
  },
  watch: {
    isVisibleAddRegion(newVal, oldVal) {
      this.isVisibleFormRegion = newVal;
    }
  }
};
</script>


<style lang='scss' scoped>
h2 {
  margin: 28px 0 24px 0;
  align-self: flex-start;
}

.empty-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-region-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}
</style>