<template>
  <div class="row wrapper wrapper--zones">
    <div class="col-12 come-back">
      <img src="../../../img/arrow-back.svg" @click="goToBack()" />
      <p>{{region.name}}</p>
    </div>
    <div class="col-12">
      <div class="form form--zone" :class="[zone.id ? 'form--zone--edit' : 'form--zone--create']">
        <div class="row">
          <div class="col-12 mb-2" v-if="projectType!=='kapous'">
            <div class="row">
              <div class="col-6">
                <input type="radio" class="radio" min="0" id="radio1" name="price_tipe" />
                <label for="radio1">Фикс</label>
              </div>
              <div class="col-6">
                <input type="radio" class="radio" id="radio2" name="price_tipe" />
                <label for="radio2">Источник</label>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <input type="text" class="input" placeholder="Название" v-model="zone.name" />
          </div>
          <div class="col-12 mb-2">
            <div class="row">
              <div class="col-12">
                <h4>Доставка</h4>
                <h5 class="addition__title">Условния по умолчанию</h5>
              </div>

              <div class="col-12 mb-2 input--price">
                <input type="number" class="input" placeholder="Цена" v-model="zone.price" />
              </div>
              <div class="col-12 mb-2 input--delivery-days">
                <input
                  type="number"
                  class="input"
                  placeholder="Срок доставки"
                  v-model="zone.delivery_days"
                />
              </div>
            </div>
          </div>
          <div class="col-12 addition">
            <div class="row" v-for="(item, index) in zone.additions" :key="index">
              <div class="col-12">
                <hr />
                <div class="row mb-2">
                  <h5 class="col-10 addition__title">Сумма заказа</h5>
                  <div class="col-2 d-flex justify-content-end align-items-center">
                    <i class="fas fa-times addition__delete" @click="deleteAddition(item.id)"></i>
                  </div>
                </div>
              </div>

              <div class="col-5 addition__input">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="от"
                  v-model="item.orderSum.from"
                />
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">-</div>
              <div class="col-5 addition__input">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="до"
                  v-model="item.orderSum.to"
                />
              </div>
              <div class="col-12 mt-2 mb-3 addition__input">
                <input
                  type="number"
                  min="0"
                  class="input"
                  placeholder="Цена"
                  v-model="item.deliveryPrice"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <button class="btn btn--white w-100" @click="addConditions()">+ Добавить условие</button>
          </div>
          <div class="col-12 mb-3">
            <textarea
              cols="100"
              class="form-control"
              rows="5"
              placeholder="Описание"
              v-model="zone.description"
            ></textarea>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <color-picker v-model="zone.color" :width="100" :height="100"></color-picker>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <p class="error">{{error}}</p>
          </div>
          <div class="col-12">
            <button
              class="btn btn-primary w-100"
              @click="addNewZone()"
              :disabled="!formIsValid"
              v-if="!zone.id"
            >Добавить</button>
            <button class="btn btn-primary w-100" @click="editZone()" v-else>Сохранить</button>
          </div>
        </div>
        <div class="row form__buttons" v-if="zone.id">
          <div class="col-6">
            <button type="button" data-toggle="modal" data-target=".modal-delete">Удалить зону</button>
          </div>
          <div class="col-6">
            <button
              type="button"
              data-toggle="modal"
              :disabled="$store.state.regions.regions.length < 2"
              data-target=".modal-move"
            >Переместить зону</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cancel" @click="doCloseFormZone()">Отмена</div>

    <div
      class="modal fade modal-delete"
      ref="modal"
      id="modal-delete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Вы действительно хотите удалить зону?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Нажимая на кнопку «Удалить зону» вы удаляете данную зону.</p>
          </div>
          <div class="modal-footer justify-content-start pt-0">
            <button
              type="button"
              class="btn modal__primary"
              data-dismiss="modal"
              data-toggle="modal"
              @click="deleteZone()"
            >Удалить зону</button>

            <p class="cancel modal__cancel m-0" data-dismiss="modal">Отмена</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-move"
      ref="modal"
      id="modal-move"
      tabindex="-1"
      role="dialog"
      aria-labelledby="moveModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="moveModalLabel">Выберите область для перемещения</h5>
          </div>
          <div class="modal-body pb-0">
            <select @change="changeMoveRegion($event)" class="w-100 mb-2 select">
              <option
                :value="item.id"
                :selected="item.id === region.id"
                v-for="item in regions"
                :key="item.id"
              >{{item.name}}</option>
            </select>
            <select @change="changeMoveZone($event)" class="w-100 mb-4 select">
              <option
                :value="item.id"
                :selected="item.id === zone.id"
                v-for="item in region.zones"
                :key="item.id"
              >{{item.name}}</option>
            </select>
            <button
              class="btn btn--add mb-0 w-100"
              data-dismiss="modal"
              @click="addNewRegion()"
            >+ Добавить область</button>
          </div>
          <div class="modal-footer justify-content-start pt-0 mt-4">
            <button
              type="button"
              class="btn modal__primary"
              data-target=".modal-move"
              data-dismiss="modal"
              data-toggle="modal"
              @click="doMoveZone()"
            >Переместить зону</button>

            <p class="cancel modal__cancel m-0" data-dismiss="modal">Отмена</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "vue-color-picker-wheel";
import axios from "axios";
import uuidv4 from "uuid/v4";
export default {
  data() {
    return {
      zone: {
        id: null,
        name: "",
        color: "#007bff",
        price: "",
        delivery_days: "",
        polygons: [],
        description: "",
        regionId: null
      },
      region: {
        id: null,
        name: "",
        color: "",
        price: null,
        delivery_days: null,
        polygons: []
      },
      projectType: this.$store.state.common.projectType,
      regions: this.$store.state.regions.regions,
      moveRegionId: null,
      moveZoneId: this.$store.state.zones.zone.id,
      error: ""
    };
  },
  mounted() {
    this.region = this.$store.getters.getRegion;
    this.moveRegionId = this.region.id;
    this.zone = this.$store.state.zones.zone;
  },
  methods: {
    goToBack() {
      this.$store.commit("changeCanDeletePolygon", false);
      this.$store.commit("changeIsEdittigZone", false);
      this.$store.commit("changeAddControl", {
        isVisiblePanel: false,
        addBtn: false,
        editPanel: false,
        areaEditShow: false
      });

      this.$store.commit("changeActiveScreen", "zoneList");
    },
    doCloseFormZone() {
      this.$store.commit("changeCanDeletePolygon", false);
      this.$store.commit("changeIsEdittigZone", false);
      this.$store.commit("changeAddControl", {
        isVisiblePanel: false,
        addBtn: false,
        editPanel: false,
        areaEditShow: false
      });
      this.$store.commit("changeActiveScreen", "zoneList");
    },
    getCurrentZone() {
      return {
        name: this.name,
        price: this.price,
        description: this.description,
        color: this.color,
        delivery_days: this.delivery_days,
        polygons: []
      };
    },
    addNewZone() {
      const data = {
        region_id: this.region.id,
        name: this.zone.name,
        price: this.zone.price,
        additions: this.zone.additions,
        description: this.zone.description,
        delivery_days: this.zone.delivery_days,
        color: this.zone.color,
        polygons: this.zone.polygons
      };

      axios
        .post("delivery-zone", data)
        .then(response => {
          this.error = "";
          axios
            .get("regions")
            .then(response => {
              this.error = "";
              let data = response.data.data;
              this.$store.commit("regionsChange", data);
              this.$store.commit("updateCities", true);
              this.$store.commit("changeActiveScreen", "zoneList");
              this.$store.commit("changeIsEdittigZone", false);
              this.$store.commit("currentRegionAddNewZone", this.region.id);
              this.$store.commit("changeAddControl", {
                isVisiblePanel: false,
                addBtn: false,
                editPanel: false,
                areaEditShow: false
              });
            })
            .catch(e => {
              this.error = "Произошла ошибка сервера";
            });
        })
        .catch(e => {
          this.error = "Произошла ошибка сервера";
        });
    },
    editZone() {
      const data = {
        region_id: this.region.id,
        name: this.zone.name,
        additions: this.zone.additions,
        price: this.zone.price,
        description: this.zone.description,
        delivery_days: this.zone.delivery_days,
        color: this.zone.color,
        polygons: this.zone.polygons
      };

      axios
        .put(`delivery-zone/${this.zone.id}`, data)
        .then(response => {
          this.error = "";
          axios
            .get("regions")
            .then(response => {
              this.error = "";
              this.$store.commit("changeCanDeletePolygon", false);
              this.$store.commit("changeIsEdittigZone", false);
              let data = response.data.data;
              let region = this.doFindElementInList(data, this.region.id);
              this.$store.commit("regionsChange", data);
              this.$store.commit("regionChange", region);
              this.$store.commit("changeActiveScreen", "zoneList");
              this.$store.commit("changeAddControl", {
                isVisiblePanel: false,
                addBtn: false,
                editPanel: false,
                areaEditShow: false
              });
            })

            .catch(e => {
              this.error = "Произошла ошибка сервера";
            });
        })
        .catch(e => {
          this.error = "Произошла ошибка сервера";
        });
    },
    doFindElementInList(list, id) {
      const element = list.find(element => element.id == id);
      return element;
    },
    deleteZone() {
      axios
        .post(`delivery-zone/${this.zone.id}`)
        .then(response => {
          this.error = "";
          axios.get("regions").then(response => {
            let data = response.data.data;
            let region = this.doFindElementInList(data, this.region.id);
            this.$store.commit("regionsChange", data);
            this.$store.commit("regionChange", region);
            this.$store.commit("changeActiveScreen", "zoneList");
            this.$store.commit("changeIsEdittigZone", false);
          });
        })
        .catch(e => {
          this.error = "Произошла ошибка сервера";
        });
    },
    doMoveZone() {
      axios
        .post("update-zones-region", {
          region_id: this.moveRegionId,
          zones: [this.moveZoneId]
        })
        .then(response => {
          this.error = "";
          axios
            .get("regions")
            .then(response => {
              this.error = "";
              let data = response.data.data;
              this.$store.commit("regionsChange", data);
              this.$store.commit("changeActiveScreen", "regionList");
              this.$store.commit("changeAddControl", {
                isVisiblePanel: false,
                addBtn: false,
                editPanel: false,
                areaEditShow: false
              });
            })
            .catch(e => {
              this.error = "Произошла ошибка сервера";
            });
        })
        .catch(e => {
          this.error = "Произошла ошибка сервера";
        });
    },
    changeMoveRegion(e) {
      this.moveRegionId = e.target.value;
    },
    changeMoveZone(e) {
      this.moveZoneId = e.target.value;
    },
    addNewRegion() {
      this.$store.commit("regionChange", {});
      this.region = {};
      this.$store.commit("changeActiveScreen", "formRegions");
      this.$store.commit("changeAddControl", {
        isVisiblePanel: false,
        addBtn: false,
        editPanel: false,
        areaEditShow: false
      });
    },
    addConditions() {
      this.zone.additions.push({
        id: uuidv4(),
        orderSum: {
          from: null,
          to: null
        },
        deliveryPrice: null
      });
    },
    deleteAddition(id) {
      this.zone.additions = this.zone.additions.filter(a => a.id !== id);
    }
  },
  computed: {
    stateZone() {
      return this.$store.getters.getZone;
    },
    formIsValid() {
      if (
        !this.zone.polygons.length ||
        !this.zone.name ||
        !this.zone.price ||
        !this.zone.delivery_days ||
        !this.zone.color
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    zone: {
      handler(newVal) {
        this.$store.commit("changeCurrentZone", newVal);
      },
      deep: true
    },
    stateZone(n, o) {
      this.zone = n;
    }
  },
  components: {
    ColorPicker
  }
};
</script>