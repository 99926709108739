<template>
  <div class="add-region-control">
    <div class="add-region-control__add" v-if="addPolygonsControl.addBtn">
      <div class="btn btn-primary w-100" @click="startAddPolygons()">+ Добавить полигон</div>
    </div>

    <div class="add-region-control__edit" v-if="addPolygonsControl.editPanel">
      <div class="add-region-control__edit__add" @click="doCreatePolygon()">
        <div>
          <i class="fas fa-plus"></i>
        </div>
      </div>

      <div class="control-panel">
        <div class="control-panel__item control-panel__undo">
          <button
            type="button"
            class="fas fa-undo"
            style="border:none"
            @click="doUndo()"
            :disabled="$store.state.zones.historyIndex===0"
          ></button>
        </div>
        <div class="control-panel__item control-panel__redo">
          <button
            type="button"
            class="fas fa-redo"
            style="border:none"
            @click="doRedo()"
            :disabled="$store.state.zones.historyIndex === $store.state.zones.history.length -1 || $store.state.zones.history.length===0"
          ></button>
        </div>
        <div class="control-panel__item control-panel__trash">
          <button
            type="button"
            class="fas fa-trash"
            :class="[$store.state.zones.zone.polygons.length < 2 ? 'disabled' : null]"
            :disabled="$store.state.zones.zone.polygons.length < 2"
            @click="deletePolygon()"
            style="border:none"
          ></button>
        </div>
      </div>

      <div class="add-region-control__select">
        <i class="fas fa-eye" v-if="editType==='show'"></i>
        <i class="fas fa-pencil-alt" v-if="editType==='edit'"></i>
        <select v-model="editType" @change="changeEditType()">
          <option value="edit">Зона редактирования</option>
          <option value="show">Зона просмотра</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addPolygonsControl: {
        isVisiblePanel: false,
        addBtn: false,
        editPanel: false
      },
      editType: "edit",
      history: [],
      region: this.$store.getters.getRegion
    };
  },
  methods: {
    startAddPolygons() {
      this.$store.commit("changeAddControl", {
        isVisiblePanel: true,
        addBtn: false,
        editPanel: true
      });
    },
    doCreatePolygon() {
      this.$store.commit("changeCanAddNewPolygon", false);
      this.$store.commit("changeCanAddNewPolygon", true);
    },
    deletePolygon() {
      this.$store.commit("changeCanDeletePolygon", true);
    },
    changeEditType() {
      this.$store.commit("changeEditType", this.editType);
    },
    doUndo() {
      this.$store.commit("doUndo");
    },
    doRedo() {
      this.$store.commit("doRedo");
    }
  },
  mounted() {
    this.addPolygonsControl = this.$store.getters.getAddControl;
  },
  computed: {
    addPolygonControl() {
      return this.$store.getters.getAddControl;
    }
  },
  watch: {
    addPolygonControl(n, o) {
      this.addPolygonsControl = this.$store.getters.getAddControl;
    }
  }
};
</script>
