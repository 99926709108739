<template>
  <div class="row wrapper wrapper--grey wrapper--start">
    <div class="col-12 add-region">
      <h2 v-if="region.id">Редактирование области</h2>
      <h2 v-else>Добавление области</h2>

      <div class="form form--region">
        <input type="text" class="input" placeholder="Название" v-model="region.name" />

        <div class="row">
          <div class="col-12">
            <label class="label">Центральная точка области</label>
          </div>
          <div class="col-6">
            <input type="number" class="input" placeholder="Широта" v-model="lat" />
          </div>
          <div class="col-6">
            <input type="number" class="input" placeholder="Долгота" v-model="long" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label
              class="label"
            >Масштаб ({{region.scale ? region.scale : $store.state.common.scale}})</label>
          </div>
          <div class="col-12">
            <input
              type="range"
              class="range w-100"
              @change="scaleChanged()"
              v-model="region.scale"
              min="3"
              max="17"
              step="1"
            />
          </div>
        </div>
        <button class="btn btn-primary w-100" v-if="region.id" @click="saveRegion()">Сохранить</button>
        <button
          class="btn btn-primary w-100"
          v-else
          @click="saveRegion()"
          :disabled="!region.name"
        >Добавить</button>

        <div class="row mt-3" v-if="!region.id">
          <div class="col-12 d-flex justify-content-center error">
            <p>{{error}}</p>
          </div>
        </div>

        <div
          class="modal fade"
          ref="modal"
          id="deleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  id="deleteModalLabel"
                >Вы действительно хотите удалить область?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Нажимая на кнопку «Удалить область» вы соглашаетесь с тем, что вы удалаете данную область и перемещаете все зоны данной области в другую или удаляете их.</p>
              </div>
              <div class="modal-footer justify-content-start">
                <button
                  type="button"
                  class="btn modal__danger"
                  data-target=".modal-move"
                  data-dismiss="modal"
                  data-toggle="modal"
                >Удалить область</button>

                <p class="cancel modal__cancel" data-dismiss="modal">Отмена</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade modal-move"
          id="modal-move"
          tabindex="-1"
          role="dialog"
          aria-labelledby="modalMoveLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  id="modalMoveLabel"
                >Вы хотите удалить зоны входящие в область?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Нажимая на кнопку «Удалить зоны» вы удалите все зоны входящие в данную область. Также вы можете их переместить в другую область нажав на кнопку «Переместить зоны»</p>
              </div>
              <div class="modal-footer justify-content-start">
                <button
                  type="button"
                  class="btn modal__danger"
                  data-target=".modal-move"
                  data-dismiss="modal"
                  data-toggle="modal"
                  @click="deleteRegion()"
                >Удалить область</button>

                <button
                  type="button"
                  class="btn modal__primary"
                  data-target=".zone-move"
                  data-dismiss="modal"
                  data-toggle="modal"
                  :disabled="$store.state.regions.regions.length<2"
                >Переместить зоны</button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade zone-move"
          tabindex="-1"
          role="dialog"
          id="zone-move"
          aria-labelledby="zoneMoveLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="zoneMoveLabel">Выберите область для перемещения</h5>
              </div>
              <div class="modal-body pb-0">
                <select @change="changeMoveRegion($event)" class="select">
                  <option
                    :value="item.id"
                    :selected="item.id === region.id"
                    :disabled="item.id === region.id"
                    v-for="item in regions"
                    :key="item.id"
                  >{{item.name}}</option>
                </select>
                <div
                  class="regions__add mb-0"
                  data-dismiss="modal"
                  @click="addNewRegion()"
                >+ Добавить область</div>
              </div>
              <div class="modal-footer justify-content-start">
                <button
                  type="button"
                  class="btn modal__primary"
                  data-dismiss="modal"
                  @click="doMoveZones()"
                >Переместить зоны</button>
                <p class="cancel modal__cancel" data-dismiss="modal">Отмена</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div
              class="delete"
              v-if="region.id"
              data-toggle="modal"
              data-target="#deleteModal"
            >Удалить</div>
          </div>
        </div>

        <div class="row mt-3" v-if="region.id">
          <div class="col-12 d-flex justify-content-center error">
            <p>{{error}}</p>
          </div>
        </div>
      </div>

      <div class="cancel" @click="doChangeVisibleFormRegion()">Отмена</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import screens from "../../screens";
export default {
  props: {
    lat: {
      type: Number,
      required: true
    },
    long: {
      type: Number,
      required: true
    },
    scope: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      screens,
      region: {},
      regions: this.$store.state.regions.regions,
      id: null,
      moveId: null,
      error: ""
    };
  },
  methods: {
    scaleChanged() {
      this.$store.commit("scaleChange", this.region.scale);
    },
    saveRegion() {
      if (!this.region.id) {
        axios
          .post("regions", {
            name: this.region.name,
            coordinates: [
              this.region.latitude || this.lat,
              this.region.longitude || this.long
            ],
            scale: this.region.scale || this.scope
          })
          .then(response => {
            this.id = response.data.id;
            this.error = "";
            axios
              .get("regions")
              .then(response => {
                this.error = "";
                let data = response.data.data;
                let region = this.doFindElementInList(data, this.id);

                this.$store.commit("regionsChange", data);
                this.$store.commit("regionChange", region);

                region.zones.length
                  ? this.$store.commit("changeActiveScreen", "zoneList")
                  : this.$store.commit("changeActiveScreen", "emptyZones");
              })
              .catch(e => {
                this.error = "Произошла ошибка сервера";
              });
          })
          .catch(e => {
            this.error = "Произошла ошибка сервера";
          });
      } else {
        const id = this.region.id;
        axios
          .put(`regions/${id}`, {
            name: this.region.name,
            coordinates: [this.region.latitude, this.region.longitude],
            scale: this.region.scale
          })
          .then(response => {
            this.error = "";
            axios
              .get("regions")
              .then(response => {
                this.error = "";
                let data = response.data.data;
                let region = this.doFindElementInList(data, id);

                this.$store.commit("regionsChange", data);
                this.$store.commit("regionChange", region);
                this.$store.commit("changeActiveScreen", "regionList");
              })
              .catch(e => {
                this.error = "Произошла ошибка сервера";
              });
          })
          .catch(e => {
            this.error = "Произошла ошибка сервера";
          });
      }
    },
    deleteRegion() {
      const id = this.region.id;
      axios
        .post(`regions/${id}`)
        .then(response => {
          this.error = "";
          axios
            .get("regions")
            .then(response => {
              this.error = "";
              let data = response.data.data;
              let region = this.doFindElementInList(data, id);
              this.$store.commit("regionsChange", data);
              this.$store.commit("regionChange", region);
              this.$store.commit("updateCities", true);
              this.$store.commit("changeActiveScreen");
              if (data.length > 0) {
                this.$store.commit("changeActiveScreen", "regionList");
              } else {
                this.$store.commit("changeActiveScreen", "emptyRegions");
              }
              this.$refs.modal.style.display = "none";
              document.querySelector(".modal-backdrop").style.display = "none";
            })
            .catch(e => {
              this.error = "Произошла ошибка сервера";
            });
        })
        .catch(e => {
          this.error = "Произошла ошибка сервера";
        });
    },
    doChangeVisibleFormRegion() {
      this.$store.commit("changeActiveScreen", "regionList");
    },
    doFindElementInList(list, id) {
      const element = list.find(element => element.id == id);
      return element;
    },
    changeMoveRegion(e) {
      this.moveId = e.target.value;
    },
    doMoveZones() {
      let zones = this.region.zones.map(zone => zone.id);

      axios
        .post("update-zones-region", {
          region_id: this.moveId,
          zones: zones
        })
        .then(response => {
          this.deleteRegion();
        })
        .catch(e => {});
    },
    addNewRegion() {
      this.$store.commit("regionChange", {});
      this.region = {};
      this.$store.commit("changeActiveScreen", "formRegions");
    }
  },
  mounted() {
    let currentRegion = this.$store.getters.getRegion;
    if (currentRegion) {
      this.moveId = currentRegion.id;
      this.region = currentRegion;
      this.region.scale = this.scope;
      this.region.latitude = this.lat;
      this.region.longitude = this.long;
    }
  },

  computed: {
    getScale() {
      return this.$store.getters.getScale;
    }
  },
  watch: {
    lat(newVal, oldVal) {
      this.region.latitude = newVal;
    },
    long(newVal, oldVal) {
      this.region.longitude = newVal;
    },
    getScale(n, o) {
      this.region.scale = n;
    }
  }
};
</script>

<style lang='scss' scoped>
h2 {
  margin: 28px 0 24px 0;
  align-self: flex-start;
}
.add-region {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input,
.range {
  margin-bottom: 25px;
}

.cancel,
.delete {
  margin-top: 25px;
  cursor: pointer;
}
.delete {
  display: inline-block;
}
.select {
  width: 100%;
  margin-bottom: 20px;
}
</style>
